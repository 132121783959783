import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    current_plan_data: {},
    today_date: ''
}

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setCurrentPlanData: (state, action) => {
            state.current_plan_data = action.payload
        },
        setTodayDate: (state, action) => {
            state.today_date = action.payload
        }
    }
})
export const { setTodayDate, setCurrentPlanData } = subscriptionSlice.actions

export default subscriptionSlice.reducer