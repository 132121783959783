import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    vendor: '',
    vendorList: []
}

export const vendorSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        setVendor: (state, action) => {
            state.vendor = action.payload
        },
        setVendorList: (state, action) => {
            state.vendorList = action.payload
        }
    }
})

export const { setVendor, setVendorList } = vendorSlice.actions

export default vendorSlice.reducer