let apiEndpoint
let commonApi
let gstApi
let sampleFileApi
let socketApi
let staffError = 'addStaffError'
let demouser = ''
let appRedirection = ''
let whatsappLinkApi
let generateotp = function () { window.open('https://themunim.com/helpdesk/how-can-i-activate-or-de-activate-an-api-session/') }
const exportFileApi = 'https://munim.blr1.digitaloceanspaces.com/'
const liveDomain = 'app.themunim.com'
const desktopAppDownloadUrl = 'https://themunim.com/download/'
const downloadSampleFilePath = `${exportFileApi}app/static/`
const imageFileApi = `${exportFileApi}app/live/assets/image`
const hostname = window.location.hostname
const isLive = window.location.hostname !== 'localhost'

apiEndpoint = process.env.API_ENDPOINT
commonApi = process.env.COMMON_API
gstApi = process.env.GST_API
sampleFileApi = `${exportFileApi}app/${process.env.SAMPLE_FILE_API}/`
socketApi = process.env.SOCKET_API
whatsappLinkApi = process.env.WHATSAPP_LINK_API

module.exports = {
    apiEndpoint,
    commonApi,
    gstApi,
    sampleFileApi,
    socketApi,
    exportFileApi,
    staffError,
    demouser,
    appRedirection,
    generateotp,
    isLive,
    whatsappLinkApi,
    downloadSampleFilePath,
    imageFileApi,
    desktopAppDownloadUrl,
    liveDomain
}
