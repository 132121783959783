import axios from 'axios'
import { handlePageRefresh, isIosDevice, isRouterMode } from './commonFunction'
import CryptoJS from "crypto-js"
import CommonRouter from './commonRoute'
import CommonApiEndPoint from './commonApiEndPoint'

const createGuest = require('cross-domain-storage/guest')
const { apiEndpoint, isLive, demouser } = require('./commonApi')
const service = axios.create({
  headers: {

  }
})
const handleSuccess = (response) => {
  return response
}

const handleError = (error) => {
  return Promise.reject(error)
}
service.interceptors.response.use(handleSuccess, handleError)

/**
 * IW0079
 * When someone changes in local storage detail at that time that person account will logout for security purpose
 */
const logOutOnRefreshExp = () => {
  if (demouser) {
    localStorage.clear()
    window.location.href = window.location.pathname.replace('dashboard', 'log-in')
  } else if (!isIosDevice() && isLive && (!isRouterMode('/pdf-preview') || !isRouterMode('/p/') || !isRouterMode('/s/'))) {
    window.removeEventListener("beforeunload", handlePageRefresh)
    const accountLocalStorage = createGuest(`${process.env.LOGIN_DOMAIN}/log-in?is_logout&product=app`)
    return new Promise(async (resolve) => {
      accountLocalStorage.remove('is_log', function (err, value) {
        if (!value) {
          localStorage.removeItem('access_token')
          localStorage.removeItem('_ett')
          localStorage.removeItem('is_admin')
          localStorage.removeItem('userData')
          document.cookie = `ent_dt =;expires=${new Date()}`
          localStorage.removeItem('refresh_tokens')
          localStorage.removeItem('user_ip')
          localStorage.removeItem('check_log')
        }
        window.location.href = CommonRouter.redirect_to_account
      })
      accountLocalStorage.remove('logged_app', function () { })
      accountLocalStorage.remove('common_data', function () { })
      resolve()
    })
  } else {
    if (!isRouterMode('/pdf-preview') || !isRouterMode('/p/')) {
      window.removeEventListener("beforeunload", handlePageRefresh)
      localStorage.removeItem('access_token')
      localStorage.removeItem('is_admin')
      localStorage.removeItem('refresh_tokens')
      localStorage.removeItem('userData')
      window.location.href = CommonRouter.log_in
    }
  }
}

const onCheckLogin = async () => {
  return new Promise(async (resolve) => {
    if (isLive) {
      const get_is_login = await createGuest(`${process.env.LOGIN_DOMAIN}/log-in?is_check_storage`)
      get_is_login.get('common_data', (error, value) => {
        if (value) {
          get_is_login.close()
          resolve(true)
        } else {
          logOutOnRefreshExp()
          resolve(false)
        }
      })
    }
  })
}
export const ApiCall = async (method, path, payload, header, timeout = 30000) => {
  if (header && !header['access-token'] && (!path.includes('generate-user-otp') && !path.includes('sign-up') && !path.includes('update-aff-visit-count'))) {
    logOutOnRefreshExp()
  } else {
    try {
      if (!localStorage.getItem('is_admin') && window.location.hostname !== 'demo.themunim.com' && !isIosDevice()) {
        onCheckLogin()
      }
      const responce = await service.request({
        method,
        url: apiEndpoint + path,
        responseType: 'json',
        data: payload,
        timeout,
        headers: header
      })
      return responce
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(`${error}, Server is not responding, please try again after some time`)
      }
      if (error.response?.data?.statusCode === 401 && header && !header['access-token']) {
        if (error.response.data.refresh_expire) {
          logOutOnRefreshExp()
        } else {
          return error.response
        }
      } else {
        return error.response
      }
    }
  }
}
/**
 * IW0079
 * here flag is true when api call occur and user is not login
 */
export const GetApiCall = async (method, path, header, flag = false, isFullPath = false, timeout = 300000) => {
  if (!header['access-token'] && !flag) {
    logOutOnRefreshExp()
  } else {
    try {
      if (!localStorage.getItem('is_admin') && !demouser && !isIosDevice()) {
        onCheckLogin()
      }
      const responce = await service.request({
        method,
        url: isFullPath ? path : apiEndpoint + path,
        responseType: 'json',
        headers: header,
        timeout
      })
      if (path === CommonApiEndPoint.user_details && !responce.data.data.user_status) logOutOnRefreshExp()
      return responce
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(`${error}, Server is not responding, please try again after some time`)
      }
      if (error.response?.data?.statusCode === 401) {
        if (error.response.data.refresh_expire) {
          logOutOnRefreshExp()
        } else {
          return error.response
        }
      } else {
        return error.response
      }
    }
  }
}

export const to_encrypt = (normalText) => {
  // Encrypt
  // REACT_APP_DEV_CHAT_KEY
  // REACT_APP_LIVE_CHAT_KEY
  const key = process.env.REACT_APP_CHAT_KEY
  const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(normalText), key).toString()
  return encryptedText
}

export const to_decrypt = (encryptedText) => {
  // Decrypt
  const key = process.env.REACT_APP_CHAT_KEY
  const bytes = CryptoJS.AES.decrypt(encryptedText, key)
  const decryptedText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedText
}