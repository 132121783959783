import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    toggleItemsView: {}
}

export const toggleItemViewSlice = createSlice({
    name: 'toggleItemView',
    initialState,
    reducers: {
        setToggleItemViewRemove: (state, action) => {
            state.toggleItemsView = action.payload
        },
        setToggleItemView: (state, action) => {
            state.toggleItemsView = { ...state.toggleItemsView, [action.payload]: !state.toggleItemsView[action.payload] }
        },
        setShowHideViewItem: (state, action) => {
            state.toggleItemsView = { ...state.toggleItemsView, ...action.payload }
        }
    }
})

export const { setToggleItemView, setToggleItemViewRemove, setShowHideViewItem } = toggleItemViewSlice.actions

export default toggleItemViewSlice.reducer