import React from 'react'

const PaymentSvg = () => {
    return (
        <svg className={'cursor-pointer payment-svg'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.625 3.536c-1.31 0-2.358 1.04-2.358 2.308v8.503c0 1.269 1.049 2.309 2.358 2.309h6.84a.636.636 0 0 0 .44-.176.6.6 0 0 0 0-.864.636.636 0 0 0-.44-.176h-6.84a1.099 1.099 0 0 1-1.106-1.093V5.844A1.1 1.1 0 0 1 3.625 4.75h15.584a1.1 1.1 0 0 1 1.106 1.093v4.71a.6.6 0 0 0 .185.431.636.636 0 0 0 .881 0 .6.6 0 0 0 .186-.431v-4.71c0-1.268-1.049-2.308-2.358-2.308H3.625Z" fill="#000" stroke="#000" strokeWidth=".3" />
            <path d="M1.445 6.46a.608.608 0 0 0 .43 1.038h18.314a.608.608 0 0 0 0-1.215H1.875a.608.608 0 0 0-.43.178Zm1.831 4.58a.608.608 0 0 0 .43 1.037h1.832a.608.608 0 0 0 0-1.216H3.706a.608.608 0 0 0-.43.178Zm0 2.289a.608.608 0 0 0 .43 1.037H7.37a.608.608 0 0 0 0-1.216H3.706a.608.608 0 0 0-.43.179Zm11.358.827a3.97 3.97 0 1 1 5.616 5.615 3.97 3.97 0 0 1-5.616-5.615Zm-1.984.823a5.186 5.186 0 1 0 9.582 3.969 5.186 5.186 0 0 0-9.582-3.97Z" fill="#000" stroke="#000" strokeWidth=".3" />
            <path d="M16.75 19.2a.442.442 0 0 1-.633.025l.632-.026Zm0 0 3.076-3.415a.443.443 0 0 0 .014-.577V15.2l-.051-.045a.442.442 0 0 0-.62.037l-2.647 2.933a.143.143 0 0 1-.201.011l-1.381-1.242m1.81 2.305-1.81-2.305m0 0-.001-.001m0 0a.443.443 0 0 0-.643.055l.643-.055Z" fill="#000" stroke="#000" strokeWidth=".3" />
        </svg>
    )
}

export default PaymentSvg
