import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    menu_list_data: []
}

export const globalSearchSlice = createSlice({
    name: 'globalSearch',
    initialState,
    reducers: {
        setMenuListData: (state, action) => {
            state.menu_list_data = action.payload
        }
    }
})

export const { setMenuListData } = globalSearchSlice.actions

export default globalSearchSlice.reducer