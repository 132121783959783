import React from 'react'

const RepeatSvg = () => {
    return (
        <svg className={'cursor-pointer'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3889 7.31133L9.83423 11.8863V9.3832C9.83423 9.26133 9.73579 9.1582 9.60923 9.1582L8.9811 9.14414C8.08579 9.10664 7.20923 9.20039 6.37954 9.43008C4.71079 9.92227 3.44985 10.8457 2.3811 12.3879V11.6801C2.38579 11.6566 2.39048 11.6285 2.39517 11.5957C2.39517 10.8926 2.52642 10.152 2.76548 9.46758C3.62329 7.07695 5.94829 5.4832 8.55923 5.4832H9.59985C9.72642 5.4832 9.82485 5.38008 9.82485 5.2582V2.73633L15.3889 7.31133ZM21.6186 11.6238V12.3363C21.6092 12.3598 21.6045 12.3879 21.6045 12.416C21.6045 13.1191 21.4733 13.8598 21.2342 14.5441C20.3764 16.9348 18.0514 18.5285 15.4405 18.5285H14.3999C14.2733 18.5285 14.1702 18.6316 14.1702 18.7582V21.2613L8.61548 16.691L14.1655 12.1207V14.6238C14.1655 14.7457 14.2639 14.8488 14.3905 14.8535L15.0186 14.8676C15.9139 14.9051 16.7905 14.8113 17.6202 14.5816C19.2889 14.0848 20.5452 13.166 21.6186 11.6238Z" fill="black" />
        </svg>
    )
}

export default RepeatSvg
