import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    support_chat_data: false,
    support_chat_status: '',
    support_chat_open: false,
    quick_draft_open: false
}

export const supportChatSlice = createSlice({
    name: 'einvoice',
    initialState,
    reducers: {
        setSupportChatData: (state, action) => {
            state.support_chat_data = action.payload
        },
        setSupportChatStatus: (state, action) => {
            state.support_chat_status = action.payload
        },
        setSupportChatOpen: (state, action) => {
            state.support_chat_open = action.payload
        },
        setQuickDraftOpen: (state, action) => {
            state.quick_draft_open = action.payload
        }
    }
})

export const { setSupportChatData, setSupportChatStatus, setSupportChatOpen, setQuickDraftOpen } = supportChatSlice.actions

export default supportChatSlice.reducer