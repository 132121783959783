import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    saleBook: '',
    saleBookList: [],
    purchaseBook: '',
    purchaseBookList: [],
    mfgCompany: '',
    mfgCompanyList: []
}

export const bookSlice = createSlice({
    name: 'book',
    initialState,
    reducers: {
        setSaleBookValue: (state, action) => {
            state.saleBook = action.payload
        },
        setSaleBookList: (state, action) => {
            state.saleBookList = action.payload
        },
        setPurchaseBookValue: (state, action) => {
            state.purchaseBook = action.payload
        },
        setPurchaseBookList: (state, action) => {
            state.purchaseBookList = action.payload
        },
        setMfgCompanyValue: (state, action) => {
            state.mfgCompany = action.payload
        },
        setMfgCompanyList: (state, action) => {
            state.mfgCompanyList = action.payload
        }
    }
})

export const { setSaleBookValue, setSaleBookList, setPurchaseBookValue, setPurchaseBookList, setMfgCompanyValue, setMfgCompanyList } = bookSlice.actions

export default bookSlice.reducer