import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    inventory_setting: {},
    change_mfg_setting: false
}

export const inventorySettingSlice = createSlice({
    name: 'inventorySetting',
    initialState,
    reducers: {
        setInventorySetting: (state, action) => {
            state.inventory_setting = action.payload
        },
        setChangeMfgCompany: (state, action) => {
            state.change_mfg_setting = action.payload
        }
    }
})

export const { setInventorySetting, setChangeMfgCompany } = inventorySettingSlice.actions

export default inventorySettingSlice.reducer