import React from 'react'

const CheckRoundBlueSvg = () => {
    return (
        <svg className={'cursor-pointer'} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#1773EA" />
            <path d="M7.05054 12.3887L4.89054 10.2287C4.82369 10.1618 4.77066 10.0825 4.73449 9.99511C4.69831 9.90777 4.67969 9.81416 4.67969 9.71962C4.67969 9.62509 4.69831 9.53147 4.73449 9.44413C4.77066 9.35679 4.82369 9.27743 4.89054 9.21058C4.95739 9.14374 5.03675 9.09071 5.12409 9.05453C5.21143 9.01835 5.30504 8.99973 5.39958 8.99973C5.49412 8.99973 5.58773 9.01835 5.67507 9.05453C5.76241 9.09071 5.84177 9.14374 5.90862 9.21058L7.51854 10.8205L12.0531 5.6509C12.1146 5.57914 12.1895 5.52018 12.2738 5.4774C12.358 5.43462 12.4499 5.40884 12.5441 5.40156C12.6383 5.39427 12.733 5.40561 12.8228 5.43493C12.9126 5.46424 12.9958 5.51096 13.0676 5.57242C13.1393 5.63388 13.1983 5.70887 13.2411 5.79312C13.2839 5.87736 13.3096 5.96921 13.3169 6.06341C13.3242 6.15761 13.3129 6.25233 13.2836 6.34215C13.2542 6.43197 13.2075 6.51514 13.1461 6.5869L8.10606 12.3469C8.04167 12.4223 7.96238 12.4837 7.87318 12.527C7.78397 12.5704 7.68676 12.5949 7.58766 12.5989C7.48856 12.602 7.38985 12.585 7.29753 12.5489C7.2052 12.5128 7.12117 12.4582 7.05054 12.3887Z" fill="white" />
        </svg>
    )
}

export default CheckRoundBlueSvg
