import React from 'react'
import LogoGif from '../../../assets/images/logo/M.gif'
const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader  w-100'>
      <img src={LogoGif} />
    </div>
  )
}

export default SpinnerComponent
