import React from 'react'

const BarcodeSvg = () => {
    return (
        <svg className={'cursor-pointer'} width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.75H1.25V13.25H0V0.75ZM10 0.75H12.5V13.25H10V0.75ZM13.75 0.75H15V13.25H13.75V0.75ZM16.25 0.75H17.5V13.25H16.25V0.75ZM18.75 0.75H20V13.25H18.75V0.75ZM2.5 0.75H6.25V13.25H2.5V0.75ZM7.5 0.75H8.75V13.25H7.5V0.75Z" fill="black" />
        </svg>

    )
}

export default BarcodeSvg